import React from "react";
import styled from "styled-components";
import { graphql } from 'gatsby';
import { PageProps } from "../../types/global";
import { SEO } from '../../components/SEO';
import { SubTitle, Title, PageText } from "../../components/text/typography";

const Container = styled.div`
    max-width: 33.6rem;
    width: 100%;
    height: 100%;
    padding: 5rem 0;
    margin: 0 auto;
    @media (max-width: 650px) {
        width: 85%;
        font-size: 1em;
    }
`;

const LegalPage: React.FunctionComponent<PageProps> = (props) => {
    return (
        <>
            <SEO lang={props.pageContext.language} title="Varqa School - Legal" url={props.location.pathname} />
            <Container>
                <Title>LEGAL INFORMATION</Title>
                <SubTitle>COPYRIGHT</SubTitle>
                <PageText>All material on this website, including but not limited to, text, photographs, images, illustrations, maps, audio clips, and video clips (“Content”) is protected by copyrights, trademarks, and other intellectual property rights. Varqa School and Jr. College retains full legal protection for its Content under all applicable national and international laws.</PageText>
                <a id="privacy" />
                <SubTitle>PRIVACY</SubTitle>
                <PageText>Visitors to this Website are not tracked, except to produce aggregate statistical data which does not identify individual users. Where cookies are used to provide essential functionality, these are not used to track use of the website or to store personally-identifiable information. Steps have been taken to secure all information collected from unauthorized access, use or disclosure. Please note that this website may include links to websites with different privacy policies.</PageText>
            </Container>
        </>
    );
};

export default LegalPage;

export const query = graphql`
    query($language: String) {
        strings: strings(language: { eq: $language }) {
            ...LayoutStringsFragment
        }
    }
`;